var _____WB$wombat$assign$function_____ = function(name) {return (self._wb_wombat && self._wb_wombat.local_init && self._wb_wombat.local_init(name)) || self[name]; };
if (!self.__WB_pmw) { self.__WB_pmw = function(obj) { this.__WB_source = obj; return this; } }
{
  let window = _____WB$wombat$assign$function_____("window");
  let self = _____WB$wombat$assign$function_____("self");
  let document = _____WB$wombat$assign$function_____("document");
  let location = _____WB$wombat$assign$function_____("location");
  let top = _____WB$wombat$assign$function_____("top");
  let parent = _____WB$wombat$assign$function_____("parent");
  let frames = _____WB$wombat$assign$function_____("frames");
  let opener = _____WB$wombat$assign$function_____("opener");



(function (holder) { 

holder.___bth = { envs: { suite: {}} };


  holder.___bth.envs.suite['agendamentos'] = {};
  
  holder.___bth.envs.suite['agendamentos']['v1'] = { "host": "https://plataforma-agendamentos.betha.cloud/agendamentos/v1" };
  

  holder.___bth.envs.suite['aplicacoes'] = {};
  
  holder.___bth.envs.suite['aplicacoes']['v1'] = { "host": "https://aplicacoes.studio.betha.cloud/aplicacoes" };
  

  holder.___bth.envs.suite['assinador'] = {};
  
  holder.___bth.envs.suite['assinador']['v1'] = { "host-oc": "https://assinador.cloud.betha.com.br/assinador/v1",
"host": "https://plataforma-assinador.betha.cloud/assinador/v1" };
  
  holder.___bth.envs.suite['assinador']['v2'] = { "host": "https://plataforma-api-assinador.betha.cloud" };
  

  holder.___bth.envs.suite['assinador-ui'] = {};
  
  holder.___bth.envs.suite['assinador-ui']['ferramenta'] = { "host": "https://assinador.plataforma.betha.cloud" };
  

  holder.___bth.envs.suite['auditoria'] = {};
  
  holder.___bth.envs.suite['auditoria']['gerenciador'] = { "host": "https://auditoria.cloud.betha.com.br" };
  

  holder.___bth.envs.suite['autorizacoes-ui'] = {};
  
  holder.___bth.envs.suite['autorizacoes-ui']['stable'] = { "host-prod": "https://autorizacoes.plataforma.betha.cloud" ,
"host": "https://autorizacoes.plataforma.betha.cloud" };
  

  holder.___bth.envs.suite['avisos'] = {};
  
  holder.___bth.envs.suite['avisos']['v1'] = { "host-oc": "https://avisos.cloud.betha.com.br",
"host": "https://plataforma-avisos.betha.cloud" };
  

  holder.___bth.envs.suite['cadastro-unico'] = {};
  
  holder.___bth.envs.suite['cadastro-unico']['gerenciador'] = { "host": "https://cadastrounico.cloud.betha.com.br" };
  

  holder.___bth.envs.suite['campos-adicionais'] = {};
  
  holder.___bth.envs.suite['campos-adicionais']['flexibilizacoes'] = { "host-oc": "https://cpa.cloud.betha.com.br/flexibilizacoes",
"host": "https://plataforma-cpa.betha.cloud/flexibilizacoes"  };
  
  holder.___bth.envs.suite['campos-adicionais']['gerenciador'] = { "host": "https://campos-adicionais.plataforma.betha.cloud" };
  
  holder.___bth.envs.suite['campos-adicionais']['v1'] = { "host-oc": "https://cpa.cloud.betha.com.br/campos-adicionais",
"host":"https://plataforma-cpa.betha.cloud/campos-adicionais"  };
  

  holder.___bth.envs.suite['campos-adicionais-ui'] = {};
  
  holder.___bth.envs.suite['campos-adicionais-ui']['v1'] = { "host": "https://cpa.cloud.betha.com.br/campos-adicionais-ui/v1" };
  

  holder.___bth.envs.suite['central-de-ajuda'] = {};
  
  holder.___bth.envs.suite['central-de-ajuda']['v1'] = { "host-redirecionamento": "http://centraldeajuda.betha.com.br/redirecionamento" };
  

  holder.___bth.envs.suite['central-usuarios'] = {};
  
  holder.___bth.envs.suite['central-usuarios']['v1'] = { "host": "https://e-gov.betha.com.br/centraldousuario"  };
  

  holder.___bth.envs.suite['certificados'] = {};
  
  holder.___bth.envs.suite['certificados']['v1'] = { "host": "https://plataforma-api-certificados.betha.cloud" };
  

  holder.___bth.envs.suite['contas-usuarios'] = {};
  
  holder.___bth.envs.suite['contas-usuarios']['v1'] = { "host": "https://e-gov.betha.com.br/contasusuarios" };
  

  holder.___bth.envs.suite['criticas-ui'] = {};
  
  holder.___bth.envs.suite['criticas-ui']['prototipo'] = { "host": "https://e-gov.betha.com.br/prototipos/criticas" };
  

  holder.___bth.envs.suite['dados'] = {};
  
  holder.___bth.envs.suite['dados']['v1'] = { "host-oc": "https://dados.cloud.betha.com.br/dados",
"host":"https://plataforma-dados.betha.cloud/dados" };
  

  holder.___bth.envs.suite['dados-ui'] = {};
  
  holder.___bth.envs.suite['dados-ui']['v1'] = { "host": "https://dados.plataforma.betha.cloud" };
  

  holder.___bth.envs.suite['documentos'] = {};
  
  holder.___bth.envs.suite['documentos']['ui/v1'] = { "host": "https://documentos.plataforma.betha.cloud" };
  
  holder.___bth.envs.suite['documentos']['v1'] = {"systemId":"178","host":"https://plataforma-documentos.betha.cloud"};
  

  holder.___bth.envs.suite['gerenciador-configuracoes'] = {};
  
  holder.___bth.envs.suite['gerenciador-configuracoes']['flexibilizacoes'] = { "host": "https://plataforma-configuracoes.betha.cloud/flexibilizacoes/v1" };
  
  holder.___bth.envs.suite['gerenciador-configuracoes']['v1'] = { "host": "https://plataforma-configuracoes.betha.cloud/configuracoes/v1" };
  

  holder.___bth.envs.suite['gerenciador-eventos-ui'] = {};
  
  holder.___bth.envs.suite['gerenciador-eventos-ui']['v1'] = { "host": "https://eventos.cloud.betha.com.br/v1" };
  

  holder.___bth.envs.suite['gerenciador-extensoes-ui'] = {};
  
  holder.___bth.envs.suite['gerenciador-extensoes-ui']['v1'] = { "host": "https://gerenciador-extensoes.plataforma.betha.cloud" };
  

  holder.___bth.envs.suite['gerenciador-relatorios'] = {};
  
  holder.___bth.envs.suite['gerenciador-relatorios']['v1'] = { "host": "https://plataforma-relatorios.betha.cloud/relatorios/v1" };
  

  holder.___bth.envs.suite['gerenciador-relatorios-parametros'] = {};
  
  holder.___bth.envs.suite['gerenciador-relatorios-parametros']['v1'] = { "host": "https://plataforma-relatorios.betha.cloud/relatorios-parametros/v1" };
  

  holder.___bth.envs.suite['gerenciador-relatorios-ui'] = {};
  
  holder.___bth.envs.suite['gerenciador-relatorios-ui']['standalone'] = { "host": "https://relatorios.plataforma.betha.cloud" };
  
  holder.___bth.envs.suite['gerenciador-relatorios-ui']['v1'] = { "host": "https://relatorios.cloud.betha.com.br/v1" };
  

  holder.___bth.envs.suite['gerenciador-scripts'] = {};
  
  holder.___bth.envs.suite['gerenciador-scripts']['v1'] = { "host": "https://plataforma-scripts.betha.cloud/scripts/v1" };
  

  holder.___bth.envs.suite['gerenciador-scripts-ui'] = {};
  
  holder.___bth.envs.suite['gerenciador-scripts-ui']['standalone'] = { "host": "https://scripts.betha.cloud" };
  
  holder.___bth.envs.suite['gerenciador-scripts-ui']['v1'] = { "host": "https://scripts.cloud.betha.com.br/v1" };
  

  holder.___bth.envs.suite['licenses'] = {};
  
  holder.___bth.envs.suite['licenses']['v1'] = { "host-oc": "https://licenses.cloud.betha.com.br/licenses/v0.1" ,
"host": "https://plataforma-licencas.betha.cloud/licenses/v0.1" };
  

  holder.___bth.envs.suite['modelo-dados'] = {};
  
  holder.___bth.envs.suite['modelo-dados']['v1'] = { "host": "https://modelodados.cloud.betha.com.br/modelodados/v1" };
  

  holder.___bth.envs.suite['naturezas'] = {};
  
  holder.___bth.envs.suite['naturezas']['v1'] = { "host-oc": "https://naturezas.cloud.betha.com.br/naturezas/v1",
"host": "https://plataforma-naturezas.betha.cloud/naturezas/v1" };
  

  holder.___bth.envs.suite['notifications'] = {};
  
  holder.___bth.envs.suite['notifications']['v1'] = { "host-oc" : "https://notifications.cloud.betha.com.br/notifications",
"host": "https://plataforma-notificacoes.betha.cloud/notifications" };
  

  holder.___bth.envs.suite['notifications-ws'] = {};
  
  holder.___bth.envs.suite['notifications-ws']['v1'] = { "host-oc" : "wss://notifications.cloud.betha.com.br/notifications",
"host": "wss://plataforma-notificacoes.betha.cloud/notifications" };
  

  holder.___bth.envs.suite['oauth'] = {};
  
  holder.___bth.envs.suite['oauth']['v1'] = { "host-oc" : "https://oauth.cloud.betha.com.br/auth/oauth2",
"host" : "https://plataforma-oauth.betha.cloud/auth/oauth2" };
  

  holder.___bth.envs.suite['oauth-ui'] = {};
  
  holder.___bth.envs.suite['oauth-ui']['v3'] = { "host" : "https://e-gov.betha.com.br/auth/ui/v3/" };
  

  holder.___bth.envs.suite['orquestrador'] = {};
  
  holder.___bth.envs.suite['orquestrador']['v1'] = { "host": "https://plataforma-orquestrador.betha.cloud/orquestrador/v1" };
  

  holder.___bth.envs.suite['pendencias-cadastrais'] = {};
  
  holder.___bth.envs.suite['pendencias-cadastrais']['v1'] = { "host": "https://pendenciascadastrais.cloud.betha.com.br/pendenciascadastrais/v1" };
  

  holder.___bth.envs.suite['pendencias-cadastrais-ui'] = {};
  
  holder.___bth.envs.suite['pendencias-cadastrais-ui']['gerenciador'] = { "host": "https://pendencias-cadastrais.cloud.betha.com.br" };
  

  holder.___bth.envs.suite['pesquisa'] = {};
  
  holder.___bth.envs.suite['pesquisa']['v1'] = { "host": "https://e-gov.betha.com.br/pesquisa" };
  

  holder.___bth.envs.suite['plataforma-consulta-execucoes'] = {};
  
  holder.___bth.envs.suite['plataforma-consulta-execucoes']['v1'] = { "host": "https://consulta-execucoes.plataforma.betha.cloud"  };
  

  holder.___bth.envs.suite['plataforma-execucoes'] = {};
  
  holder.___bth.envs.suite['plataforma-execucoes']['v1'] = { "host-oc": "https://plataforma-execucoes.cloud.betha.com.br/v1"  ,
"host": "https://plataforma-execucoes.betha.cloud/v1"  };
  

  holder.___bth.envs.suite['plataforma-extensoes'] = {};
  
  holder.___bth.envs.suite['plataforma-extensoes']['v1'] = { "host": "https://plataforma-extensoes.betha.cloud"  };
  

  holder.___bth.envs.suite['plataforma-metricas'] = {};
  
  holder.___bth.envs.suite['plataforma-metricas']['v1'] = { "host": "https://plataforma-metricas.betha.cloud"  };
  

  holder.___bth.envs.suite['service-login'] = {};
  
  holder.___bth.envs.suite['service-login']['proxy'] = { "host":"https://service-login.cloud.betha.com.br/servicelogin" ,
"host_proxy":"https://api-service-login.cloud.betha.com.br/servicelogin" };
  
  holder.___bth.envs.suite['service-login']['session-verifier'] = { "host":"https://login-session-verifier.betha.cloud/api" };
  
  holder.___bth.envs.suite['service-login']['v1'] = { "host-oc": "https://service-login.cloud.betha.com.br/servicelogin",
"host": "https://login.betha.cloud/servicelogin" };
  

  holder.___bth.envs.suite['sessions'] = {};
  
  holder.___bth.envs.suite['sessions']['v1'] = { "host-oc": "https://sessions.cloud.betha.com.br/sessions",
"host": "https://plataforma-autorizacoes-sessoes.betha.cloud/user-accounts/v0.1/sessions" };
  

  holder.___bth.envs.suite['studio-ui'] = {};
  
  holder.___bth.envs.suite['studio-ui']['v1'] = {
"aplicacoes": "https://aplicacoes.studio.betha.cloud",
"store": "https://betha.store"
};
  

  holder.___bth.envs.suite['suinc'] = {};
  
  holder.___bth.envs.suite['suinc']['v1'] = { "host": "wss://plataforma-suinc.betha.cloud/v1"  };
  

  holder.___bth.envs.suite['suite'] = {};
  
  holder.___bth.envs.suite['suite']['v1'] = { "host": "https://suite.cloud.betha.com.br/suite/v1" };
  

  holder.___bth.envs.suite['suite-ui'] = {};
  
  holder.___bth.envs.suite['suite-ui']['domain-path'] = { "host": ".cloud.betha.com.br" };
  
  holder.___bth.envs.suite['suite-ui']['domain-path-cloud'] = { "host": ".betha.cloud" };
  
  holder.___bth.envs.suite['suite-ui']['home'] = { "host": "https://cloud.betha.com.br" };
  
  holder.___bth.envs.suite['suite-ui']['liberacao-licenca'] = { "host": "https://cloud.betha.com.br/#/liberacoes/entidade" };
  

  holder.___bth.envs.suite['teams'] = {};
  
  holder.___bth.envs.suite['teams']['v1'] = { "host": "https://configuracoes.studio.betha.cloud/configuracoes" };
  

  holder.___bth.envs.suite['templates'] = {};
  
  holder.___bth.envs.suite['templates']['v2'] = { "host-oc": "https://templates.cloud.betha.com.br/api/v2",
"host": "https://plataforma-cadastro-unico.betha.cloud/api/v2" };
  

  holder.___bth.envs.suite['user-accounts'] = {};
  
  holder.___bth.envs.suite['user-accounts']['sessoes'] = { "host": "https://plataforma-autorizacoes-sessoes.betha.cloud/user-accounts/v0.1", 
"host-oc": "https://useraccounts.cloud.betha.com.br/user-accounts/v0.1" };
  
  holder.___bth.envs.suite['user-accounts']['v1'] = { "host-oc": "https://useraccounts.cloud.betha.com.br/user-accounts/v0.1",
"host": "https://plataforma-autorizacoes.betha.cloud/user-accounts/v0.1" };
  

  holder.___bth.envs.suite['user-accounts-ui'] = {};
  
  holder.___bth.envs.suite['user-accounts-ui']['v1'] = { "host": "https://useraccounts.cloud.betha.com.br/user-accounts/v0.1" };
  
  holder.___bth.envs.suite['user-accounts-ui']['v2'] = { "host": "https://useraccounts.plataforma.betha.cloud" };
  

  holder.___bth.envs.suite['usuarios'] = {};
  
  holder.___bth.envs.suite['usuarios']['v1'] = { "host-oc": "https://usuarios.cloud.betha.com.br/usuarios/v0.1" ,
"host": "https://plataforma-usuarios.betha.cloud/usuarios/v0.1" };
  

  holder.___bth.envs.suite['variaveis'] = {};
  
  holder.___bth.envs.suite['variaveis']['v1'] = { "host": "https://plataforma-variaveis.betha.cloud" };
  

 
 })(window);

}